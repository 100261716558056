import { get } from "lodash";
import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const PlanChecker = () => {
    const { pathname } = useLocation();
    const { token, user } = useSelector(state => state.storage);
    const navigate = useNavigate();

    useEffect(() => {
        if (!['/profile'].includes(pathname) && !pathname.includes('/preview/') && token && !get(user, 'data.membership.id')) {
            navigate('/renew-membership');
        }
    }, [pathname]);

    return (<></>)
}
export default PlanChecker;